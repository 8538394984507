.error-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;  
}

.error-container footer p {
    font-size: 24px;
}

.error-image {
  display: flex;
  height: 70vh;
  align-items: center;
  justify-content: center;
}

.error-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.error-text h1 {
  font-size: 50px;
}

@media (max-width: 650px) {
  .error-image {
    height: 50vh;
  }

  .error-text h1 {
    font-size: 35px;
  }

  .error-container footer p {
    font-size: 18px;
  }
}
